import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-use';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import useCustomerProductLists from './useCustomerProductLists';
import { useCustomerStore } from './useCustomerStore';
import { useCommerceAPI } from '../contexts';
import { getProducts } from '~/features/product-details-page/pdp-api';
import { Product } from '~/lib/data-contract/salesforce';
const DEFAULT_WISHLIST_NAME = 'imerco';
export const DEFAULT_WISHLIST_TYPE = 'shopping_list';
const queryKey = [DEFAULT_WISHLIST_NAME, DEFAULT_WISHLIST_TYPE];

export const useWishlist = () => {
    const {
        createList,
        createListItem,
        getLists: fetchLists,
        removeListItem,
    } = useCustomerProductLists();
    const { customer } = useCustomerStore((state) => ({
        customer: state.customer,
    }));
    const { data: frame } = useFrame();
    const { wishlistEvent } = useEvents(frame as any);
    const location = useLocation();

    const { data, refetch } = useQuery({
        queryKey,
        queryFn: fetchLists,
        enabled: !!customer,
    });

    const api = useCommerceAPI();

    const findListByType = useCallback(
        (type: string = DEFAULT_WISHLIST_TYPE) => {
            return data?.data?.find((list) => list.type === type);
        },
        [data],
    );

    const addItemToList = async (productId: string) => {
        const list = findListByType();

        if (list) {
            try {
                await createListItem(list?.id, { id: productId });
                wishlistEvent('Product added to wishlist (success)', `${list.id} | ${productId}`);
                await refetch();
            } catch {
                wishlistEvent('Product added to wishlist (error)', location.pathname);
            }
        }
    };

    const removeItemFromList = async (productId: string) => {
        const product = getProductFromList(productId, DEFAULT_WISHLIST_TYPE);
        const list = findListByType();
        if (list && product) {
            try {
                await removeListItem(list.id, product.id);
                wishlistEvent(
                    'Product removed from wishlist (success)',
                    `${list.id} | ${product.id || ''}`,
                );
                await refetch();
            } catch {
                wishlistEvent('Product removed from wishlist (error)', location.pathname);
            }
        }
    };

    const getProductFromList = (productId: string, listName: string = DEFAULT_WISHLIST_TYPE) => {
        const list = findListByType(listName);
        if (list) {
            return list.customerProductListItems?.find(
                (product) => product.productId === productId,
            );
        }
        return undefined;
    };

    const isFavorite = useCallback(
        (productId: string) => {
            return getProductFromList(productId, DEFAULT_WISHLIST_TYPE) !== undefined;
        },
        [data],
    );

    const handleFavourite = useCallback(
        async (id: string) => {
            if (isFavorite(id)) {
                removeItemFromList(id);
            } else {
                addItemToList(id);
            }
        },
        [isFavorite],
    );

    const getFavorites = useCallback(
        async (ids: string) => {
            return getProducts(api, {
                ids: Array.isArray(ids) ? ids.join(',') : ids,
                allImages: true,
                currency: frame?.market?.currency?.currency,
            });
        },
        [api, frame?.market?.currency?.currency],
    );

    const createGuestUser = async () => {
        await createList(DEFAULT_WISHLIST_NAME, DEFAULT_WISHLIST_TYPE);
        await refetch();
    };

    const getProductList = async () => {
        const list = findListByType();
        const listItems = list?.customerProductListItems ?? [];
        const PRODUCT_LIMIT = 24;

        // Early return for empty lists
        if (!listItems?.length) {
            return [];
        }

        //Slice productIds by 24
        const productIds = [];
        for (let i = 0; i < listItems.length; i += PRODUCT_LIMIT) {
            productIds.push(
                listItems
                    .slice(i, i + PRODUCT_LIMIT)
                    .map((item) => item.productId)
                    .filter((id): id is string => Boolean(id)),
            );
        }

        try {
            const response = await Promise.all(
                productIds.map((item) => getFavorites(item.join(','))),
            );

            const hasData = response.some((item) => !!item.data);

            if (!hasData) return [];

            const products = response.reduce<Product[]>(
                (collection, query) => collection.concat(query.data),
                [],
            );

            return products;
        } catch (error) {
            console.error('Error filtering active products:', error);
            return [];
        }
    };

    return {
        items: findListByType()?.customerProductListItems ?? [],
        addItemToList,
        removeItemFromList,
        handleFavourite,
        isFavorite,
        getFavorites,
        isNewUser: data ? !findListByType() : undefined,
        createGuestUser,
        getProductList,
    };
};
